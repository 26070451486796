import React from "react";
import { Target, Menu, Sun, Moon } from "@geist-ui/react-icons";
import {
  Text,
  Heading,
  Box,
  Flex,
  useColorModeValue,
  VisuallyHidden,
  HStack,
  Button,
  useDisclosure,
  VStack,
  IconButton,
  CloseButton,
  useColorMode,
  Link,
} from "@chakra-ui/react";
import { motion } from "framer-motion";

export default function Nav() {
  const { toggleColorMode } = useColorMode();
  const bg = "#4829C5";
  const mobileNav = useDisclosure();
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { y: -20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };
  return (
    <React.Fragment>
      <Heading bg={bg} w="full" px={{ base: 2, sm: 4 }} py={4} shadow="md">
        <Flex alignItems="center" justifyContent="space-between" mx="auto">
          <Flex>
            <Link href="/" title="Beacon" display="flex" alignItems="center">
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 500,
                  damping: 100,
                  duration: 2,
                  repeatType: "reverse",
                  repeat: Infinity,
                }}
              >
                <Target color="#ffffff" />
              </motion.div>
              <VisuallyHidden>Beacon</VisuallyHidden>
            </Link>
            <Text fontSize="xl" fontWeight="medium" ml="2" color="gray.100">
              Beacon
            </Text>
          </Flex>
          <HStack display="flex" alignItems="center" spacing={1}>
            <HStack
              spacing={6}
              mr={5}
              color="gray.100"
              display={{ base: "none", md: "inline-flex" }}
            >
              <Button
                variant="ghost"
                bg="#181818"
                _hover={{ bg: "#050505" }}
                _active={{ bg: "#181818" }}
              >
                Plans
              </Button>
              <Button
                variant="ghost"
                bg="#181818"
                _hover={{ bg: "#050505" }}
                _active={{ bg: "#181818" }}
              >
                About
              </Button>
              <Button
                variant="ghost"
                bg="#181818"
                _hover={{ bg: "#050505" }}
                _active={{ bg: "#181818" }}
              >
                Sign in
              </Button>
            </HStack>
            <Box display={{ base: "inline-flex", md: "none" }}>
              <IconButton
                display={{ base: "flex", md: "none" }}
                aria-label="Open menu"
                fontSize="20px"
                color={useColorModeValue("gray.800", "inherit")}
                variant="ghost"
                icon={<Menu color={useColorModeValue("white", "gray")} />}
                onClick={mobileNav.onOpen}
              />

              <VStack
                pos="absolute"
                top={0}
                left={0}
                right={0}
                display={mobileNav.isOpen ? "flex" : "none"}
                flexDirection="column"
                p={2}
                pb={4}
                m={2}
                bg={bg}
                spacing={3}
                rounded="sm"
                shadow="sm"
              >
                <CloseButton
                  aria-label="Close Menu"
                  onClick={mobileNav.onClose}
                />

                <Button w="full" variant="ghost">
                  Plans
                </Button>
                <Button w="full" variant="ghost">
                  About
                </Button>
                <Button w="full" variant="ghost">
                  Sign in
                </Button>
              </VStack>
            </Box>
          </HStack>
        </Flex>
      </Heading>
    </React.Fragment>
  );
}
