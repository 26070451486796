import React from "react";
import Nav from "./lib/Nav";
import {
  Text,
  Box,
  Link,
  useColorModeValue,
  Flex,
  SimpleGrid,
  GridItem,
  VisuallyHidden,
  Input,
  Button,
  Stack,
  Icon,
} from "@chakra-ui/react";

const App = () => {
  return (
    <Box minHeight="100vh" bg={useColorModeValue("#181818", "#fffefc")}>
      <Nav />
      <Box px={4} py={32} mx="auto">
        <Box
          w={{ base: "full", md: 11 / 12, xl: 8 / 12 }}
          textAlign={{ base: "left", md: "center" }}
          mx="auto"
        >
          <Text
            mb={3}
            fontSize={{ base: "4xl", md: "5xl" }}
            fontWeight={{ base: "bold", md: "extrabold" }}
            color={useColorModeValue("gray.100", "gray.900")}
            lineHeight="shorter"
            align="center"
            bgClip="text"
            bgGradient="linear(to-r, #353fab, #8e95e8)"
          >
            Moderation for the Future.
          </Text>
          <Text
            mb={6}
            fontSize={{ base: "lg", md: "xl" }}
            color={useColorModeValue("gray.100", "gray.900")}
            lineHeight="base"
            align="center"
          >
            Beacon is a powerful, easy to use, and completely configurable
            moderation bot designed for large guilds. With strong moderation,
            proper configurability, and stand-out featurs absent in other bots,
            Beacon is the only moderation bot you'll need to keep your server
            safe.
          </Text>
          <SimpleGrid
            w={{ base: "full", md: 7 / 12, sm: 5 / 12 }}
            spacing={3}
            pt={1}
            mx="auto"
            mb={8}
          >
            <Button
              as={Link}
              w="full"
              variant="solid"
              colSpan={{ base: 6, lg: 2 }}
              size="lg"
              bg="#4829C5"
              color="#fff"
              cursor="pointer"
              _hover={{ bg: "#341d91" }}
              href="https://discord.gg/NHypW8MTxE"
              isExternal
            >
              Join Our Discord
            </Button>
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  );
};

export default App;
